<template>
  <div class="modalitiesTable">
    <Table tableClass="modalitiesTable" :key="componentKey" :headers="headers" :staticHeaders="staticHeaders"
      :items="formattedmodalities" :hiddenItems="['id', 'ordre', 'highlight', 'designationauto', 'option']"
      :tools="tools" :checkbox="checkbox" :checkboxKey="checkboxKey" :defaultSelectedItems="defaultSelectedItems"
      :edit="editTable" @editItem="editItem">

      <template v-slot:header="{ item }">
        <div class="table-header-wrapper">
          <div class="table-header-content">
            <div class="facteur-designation">
              <template v-if="item.designation">
                {{ item.designation }}
              </template>
              <template v-else>
                {{ item.type.designation }}
              </template>
            </div>
            <template v-if="item.passages">
              <span>Stade d'application&nbsp;: </span>
              <template v-for="passage in item.passages" :key="passage.id">
                <template v-if="passage.id === item.passage_id">
                  {{ passage.stade.code_bbch }} -
                  {{
                    passage.stade.designation_court
                      ? passage.stade.designation_court
                      : passage.stade.designation
                  }}
                </template>
              </template>
            </template>
            <template v-if="item.variables">
              <template v-for="variable in item.variables" :key="variable.id">
                {{ variable.type.designation }}
              </template>
            </template>

          </div>
          <div class="table-header-tools" v-if="$slots['header-tools']">
            <slot name="header-tools" :header="item"></slot>
          </div>
        </div>
      </template>
      <template v-slot:tools="{ item }">
        <slot name="tools" :item="item"></slot>
      </template>
    </Table>
    <Loader :active="loading" />
  </div>
</template>
<script>
import Table from '@/components/table/Table.vue'
import Loader from '@/components/layout/Loader.vue'

export default {
  name: 'ModalitiesTable',
  components: {
    Table,
    Loader,
  },
  props: {
    protocol_id: {
      type: [Number, String],
    },
    onlyItems: {
      type: Array,
    },
    defaultSelectedItems: {
      type: Array,
      default: null,
    },
    tools: {
      type: Boolean,
      default: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    /**
     * Key used for the loop
     *
     * @default uid
     * @example id, uid
     */
    checkboxKey: {
      type: String,
      default: 'uid',
    },
  },
  data() {
    return {
      isCollapsed: true,
      // Table
      loading: true,
      staticHeaders: [
        'Désignation',
        'Numéro local',
        'Destruction de la modalité',
        'Type',
        'Partage de la modalité',
      ],
      componentKey: 0,
      protocol: {},
      factors: {},
      headers: [],
      modalities: [],
      formattedmodalities: [],
      produits: [],
      travailsol: {
        passages: [],
      },
      variablesValues: [],
      referentiels: [],
      highlightedRow: {},
      editTable: false,
    }
  },

  watch: {
    defaultSelectedItems() {
      this.componentKey += 1
    },
  },
  mounted() {
    this.refresh()
    this.emitter.on('refresh-modalities-table', this.refresh)
    this.emitter.on('select-modalities-table', this.highlight)
  },
  methods: {
    selectItems(event) {
      this.$emit('selected-items', event)
    },
    refresh() {
      this.loading = true
      this.produits = []
      this.travailsol = {
        passages: [],
      }
      this.variablesValues = []
      this.getFactors().then(() => {
        this.getHeaders().then(() => {
          this.getModalities().then(() => {
            this.formatModalities()
          })
        })
      })
    },
    async getProtocol() {
      const response = await this.fetchService.get(
        `protocole/${this.protocol_id}`,
      )
      this.protocol = response.data
    },
    getFactors() {
      return new Promise((nextStep) => {
        const newfactors = []
        this.fetchService
          .get(`protocole/${this.protocol_id}/facteur`)
          .then((response) => {
            Promise.all(
              response.data.map((factor) => this.getPassages(factor).then(
                () => this.getVariable(factor).then(() => {
                  newfactors.push(factor)
                }),
              )),
            ).then(() => {
              this.sortByOrder(newfactors)
              this.factors = newfactors
              nextStep()
            })
          })
      })
    },
    getHeaders() {
      return new Promise((nextStep) => {
        const headers = []

        this.factors.forEach((factor, index) => {
          const header = this.helperService.cloneObject(factor)

          this.fetchService
            .get(
              `protocole/${this.protocol_id}/facteur/${header.id}/passage/produit`,
              { limit: 0 },
            )
            .then((response) => {
              if (response.data.length > 0) {
                this.produits = this.produits.concat(response.data)
              }
            })

          if (header.passages) {
            const stades = []

            header.passages.forEach((passage) => {
              stades.push({
                stade: {
                  code_bbch: passage.stade.code_bbch,
                  key: passage.stade.uid,
                  uid: passage.stade.uid,
                  passage_id: passage.id,
                  value: `${passage.stade.code_bbch} - ${passage.stade.designation_court}`,
                  designation_court: passage.stade.designation_court,
                  designation: passage.stade.designation,
                },
              })
            })

            if (header.type.uid === 'SANTEVEGETALE' || header.type.uid === 'FERTILISATION') {
              header.passages.forEach((passage) => {
                const newheader = this.helperService.cloneObject(header)
                Object.assign(newheader, {
                  id_facteur: header.id,
                  passage_id: passage.id,
                  bgdark: (index % 2 !== 0),
                  bglight: (index % 2 === 0),
                  sub_headers: this.getSubHeaders(newheader),
                })
                newheader.stades = stades
                headers.push(newheader)
              })
            }

            if (header.type.uid === 'TRAVAILDUSOL') {
              header.passages.forEach((passage) => {
                const newheader = this.helperService.cloneObject(header)
                Object.assign(newheader, {
                  id_facteur: header.id,
                  passage_id: passage.id,
                  bgdark: (index % 2 !== 0),
                  bglight: (index % 2 === 0),
                  sub_headers: this.getSubHeaders(newheader),
                })
                newheader.stades = stades
                headers.push(newheader)
                // on cherche les produits
                this.fetchService
                  .get(
                    `protocole/${this.protocol_id}/facteur/${header.id}/passage/${passage.id}/travail_du_sol`,
                    { limit: 0 },
                  )
                  .then((response) => {
                    this.travailsol.passages[passage.id] = response.data
                  })
              })
            }
          } else if (header.variables) {
            header.variables.forEach((variable) => {
              const newheader = this.helperService.cloneObject(header)
              newheader.type.designation = newheader.type.uid
              Object.assign(newheader, {
                id_facteur: header.id,
                variable_id: variable.id,
                bgdark: (index % 2 !== 0),
                bglight: (index % 2 === 0),
                sub_headers: this.getSubHeaders(newheader),
              })
              headers.push(newheader)

              this.fetchService
                .get(
                  `protocole/${this.protocol_id}/facteur/${header.id}/variable/${variable.id}/valeur`,
                  { limit: 0 },
                )
                .then((response) => {
                  this.variablesValues[header.id] = response.data
                })
            })
          } else {
            Object.assign(header, {
              id_facteur: header.id,
              bgdark: (index % 2 !== 0),
              bglight: (index % 2 === 0),
              sub_headers: this.getSubHeaders(header),
            })
            headers.push(header)
          }
        })
        this.sortByOrder(headers)
        this.headers = headers
        this.$emit('headers', this.headers)
        nextStep()
      })
    },
    getSubHeaders(item) {
      let subHeaders
      let tempsSubHeaders
      switch (item.type.uid) {
        case 'TRAVAILDUSOL':
          subHeaders = ['Valeur']
          break
        case 'PERSONNALISE':
          tempsSubHeaders = item.variables.map((variable) => {
            const subheader = []
            subheader.push('Valeur')
            if (variable.type.avecunite) {
              subheader.push('Unité')
            }
            return subheader
          })
          subHeaders = tempsSubHeaders.shift()
          break
        default:
          subHeaders = [
            'Type de produit',
            'Produit',
            'Traitement semence',
            'Densité / Dose',
            'Unite',
            'Pmg',
            'Fournisseur',
          ]
          break
      }
      return subHeaders
    },

    getModalities() {
      return new Promise((nextStep) => {
        const newmodalities = []
        this.fetchService
          .get(`protocole/${this.protocol_id}/modalite`, { limit: 0 })
          .then((response) => {
            Promise.all(
              response.data.map((modalite) => this.fetchService
                .get(
                  `protocole/${this.protocol_id}/modalite/${modalite.id}/semence`,
                  {
                    limit: 0,
                  },
                )
                .then((semenceModalities) => {
                  if (semenceModalities.data.length > 0) {
                    this.produits = this.produits.concat(semenceModalities.data)
                  }
                })
                .then(() => {
                  newmodalities.push(modalite)
                })),
            ).then(() => {
              this.sortByOrder(newmodalities)
              this.modalities = newmodalities
              this.$emit('modalities', this.modalities)
              nextStep()
            })
          })
      })
    },
    getPassages(factor) {
      return new Promise((nextStep) => {
        if (
          factor.type.uid === 'FERTILISATION'
          || factor.type.uid === 'SANTEVEGETALE'
          || factor.type.uid === 'TRAVAILDUSOL'
        ) {
          this.fetchService
            .get(`protocole/${this.protocol_id}/facteur/${factor.id}/passage`,
              { limit: 0 })
            .then((passagesRequest) => {
              if (passagesRequest.data.length > 0) {
                this.sortByOrder(passagesRequest.data)
                Object.assign(factor, { passages: passagesRequest.data })
              }
              nextStep()
            })
        } else {
          nextStep()
        }
      })
    },
    getVariable(factor) {
      return new Promise((nextStep) => {
        if (factor.type.uid === 'PERSONNALISE') {
          this.fetchService
            .get(
              `protocole/${this.protocol_id}/facteur/${factor.id}/variable`,
              { limit: 0 },
            )
            .then((variablesRequest) => {
              if (variablesRequest.data.length > 0) {
                if (variablesRequest.data.length > 0) {
                  const variablesData = variablesRequest.data[0]
                  if (variablesData.type.format_variable.uid === 'LISTE') {
                    this.getReferentiel(variablesData.type.referentiel)
                  }
                }
              }
              this.sortByOrder(variablesRequest.data)
              Object.assign(factor, {
                variables: variablesRequest.data,
              })

              nextStep()
            })
        } else {
          nextStep()
        }
      })
    },
    async formatModalities() {
      const formatteddata = []
      // eslint-disable-next-line no-restricted-syntax
      for (const modality of this.modalities) {
        let data = {}
        let tabcol = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const header of this.headers) {
          switch (header.type.uid) {
            case 'TRAVAILDUSOL':
              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getTravailSolFomModalities(
                header,
                modality,
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )

              break
            case 'PERSONNALISE':
              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getVariableFomModalities(
                header,
                modality,
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )

              break
            default:

              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getProduitsFomModalities(
                header,
                modality,
                'type',
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )

              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getProduitsFomModalities(
                header,
                modality,
                'produit',
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )
              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getProduitsFomModalities(
                header,
                modality,
                'traitementsemence',
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )
              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getProduitsFomModalities(
                header,
                modality,
                'densitedose',
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )

              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getProduitsFomModalities(
                header,
                modality,
                'unite',
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )
              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getProduitsFomModalities(
                header,
                modality,
                'pmg',
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )
              // eslint-disable-next-line no-loop-func, no-await-in-loop
              await this.getProduitsFomModalities(
                header,
                modality,
                'fournisseur',
              ).then(
                // eslint-disable-next-line no-loop-func
                (resp) => {
                  if (resp !== undefined) {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: resp } }
                  } else {
                    data = { ...data, ...{ [`${(tabcol += 1)}`]: '-' } }
                  }
                },
              )
          }
        }

        let destruction = null
        if (modality.destruction === true) {
          destruction = 'Oui'
        }
        if (modality.destruction === false) {
          destruction = 'Non'
        }
        data = {
          ...data,
          ...{
            id: modality.id,
            ordre: modality.ordre,
            designationauto: modality.designationauto,
            designation: modality.designation,
            numero: modality.numero,
            destruction,
            type: modality.type?.designation,
            partage_accepte: modality.est_enfant ? 'Oui' : 'Non',
            option: modality.option,
            highlight: modality.id === this.highlightedRow?.id,
          },
        }
        formatteddata.push(data)
      }

      this.formattedmodalities = formatteddata

      if (typeof this.onlyItems !== 'undefined' && this.onlyItems.length > 0) {
        this.formattedmodalities = formatteddata.filter(
          (data) => this.onlyItems.indexOf(data.id) !== -1,
        )
      }

      this.loading = false
    },

    async getTravailSolFomModalities(header, modality) {
      return new Promise((nextStep) => {
        let value = '-'
        let localDesignation = ''
        if (header.passage_id) {
          this.travailsol.passages[header.passage_id].forEach((travailsol) => {
            if (travailsol.modalite.id === modality.id) {
              localDesignation += travailsol?.sol_travail?.valeur
                ? `<div>${travailsol.sol_travail.valeur}</div>`
                : null
            }
          })
        }
        value = `<div>${localDesignation}</div>`
        nextStep(value)
      })
    },

    async getVariableFomModalities(header, modality) {
      return new Promise((nextStep) => {
        let value = '-'
        this.variablesValues[header.id].forEach((variable) => {
          if (variable.modalite.id === modality.id) {
            const typeVariable = header.variables[0]
            if (typeVariable) {
              if (typeVariable.type.format_variable.uid === 'LISTE') {
                const valeurRef = this.referentiels
                  .find((element) => element.uid === variable.valeur)
                value = valeurRef?.valeur
              } else if (typeVariable.type.format_variable.uid === 'DATE') {
                value = this.helperService.displayDate(variable.valeur)
              } else {
                value = variable.valeur
              }
            }
          }
        })
        nextStep(value)
      })
    },
    getProduitsFomModalities(header, modality, element) {
      return new Promise((nextStep) => {
        let value = '-'
        let localNom = ''
        let localDose = ''
        let localUnite = ''
        let localType = ''
        let localPmg = ''
        let localFournisseur = ''
        let localTraitementsemence = ''
        let localProductArray = []

        if (header.passage_id) {
          localProductArray = this.produits.filter((prod) => (prod?.modalite?.id === modality?.id && prod?.dat_passage_id === header?.passage_id))
        } else {
          localProductArray = this.produits.filter((prod) => (prod?.facteur?.id === header?.id && prod?.modalite?.id === modality?.id))
        }

        localProductArray.forEach((produit) => {
          if (produit.modalite.id === modality.id) {
            localType += produit?.produit?.type?.uid
              ? `<div>${produit.produit.type.designation}</div>`
              : '<div>-</div>'
            localNom += produit?.produit?.designation
              ? `<div>${produit.produit.designation}</div>`
              : '<div>-</div>'

            localDose += produit?.dose && !produit?.densite ? `<div>${produit.dose}</div>` : ''
            localDose += !produit?.dose && produit?.densite ? `<div>${produit.densite}</div>` : ''
            localDose += !produit?.dose && !produit?.densite ? '<div>-</div>' : ''

            localUnite += produit?.unite?.valeur
              ? `<div>${produit.unite.valeur}</div>`
              : '<div>-</div>'
            localTraitementsemence += produit?.traitementsemence
              ? `<div>${produit.traitementsemence}</div>`
              : '<div>-</div>'
            localPmg += produit?.pmg
              ? `<div>${produit.pmg}</div>`
              : '<div>-</div>'
            localFournisseur += produit?.fournisseur?.nom
              ? `<div>${produit?.fournisseur?.nom}</div>`
              : '<div>-</div>'
          }
        })

        if (element === 'type' && localType !== '') {
          value = `<div>${localType}</div>`
        }

        if (element === 'produit' && localNom !== '') {
          value = `<div>${localNom}</div>`
        }

        if (element === 'densitedose' && localDose !== '') {
          value = `<div>${localDose}</div>`
        }

        if (element === 'fournisseur' && localFournisseur !== '') {
          value = `<div>${localFournisseur}</div>`
        }

        if (element === 'unite' && localUnite !== '') {
          value = `<div>${localUnite}</div>`
        }

        if (element === 'pmg' && localPmg !== '') {
          value = `<div>${localPmg}</div>`
        }

        if (element === 'traitementsemence' && localTraitementsemence !== '') {
          value = `<div>${localTraitementsemence}</div>`
        }

        nextStep(value)
      })
    },

    highlight(data) {
      if (this.highlightedRow.id === data.id) {
        this.highlightedRow = {}
        this.editTable = false
      } else {
        this.highlightedRow = data
        this.editTable = true
      }
      this.formatModalities()
    },
    editItem(item) {
      if (this.highlightedRow.id === item.id) {
        this.highlightedRow = {}
        this.editTable = false
        this.formatModalities()
      } else {
        this.$emit('editItem', { oldModa: this.highlightedRow, newModa: item })
      }
    },
    checkIfPropertyExists(property, obj, element) {
      if (obj[property] && obj[property].length) {
        const handleProperty = (item, prop) => item.split('.').reduce((p, c) => (p && p[c]) || null, prop)
        const value = obj[property].map((p) => handleProperty(element, p))
        if (value.length > 1) {
          return value.join(', ')
        }
        return value[0]
      }

      return '—'
    },
    async getReferentiel(referentiel) {
      const response = await this.fetchService.get(
        `dictionnaire/referentiel/${referentiel.id}/valeur`,
      )
      response.data.forEach((item) => {
        this.referentiels.push(item)
      })
    },
    sortByOrder(element) {
      return element.sort((a, b) => a.ordre - b.ordre)
    },
  },
}
</script>

<style lang="scss" scoped>
.modalitiesTable {
  position: relative;

  .table {
    width: 100%;

    .tabletag {
      width: 100%;
    }
  }

  .loader {
    z-index: 15;
  }
}

.table-header-wrapper {
  display: flex;
  align-items: center;
  gap: $gutter-quarter;
}

.table-header-content {
  flex-grow: 1;
}

.table-header-tools {
  margin: -$gutter-quarter;
  padding: 0 $gutter-eighth;
}
</style>
